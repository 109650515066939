import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import 'components/content-pool/_styles.scss'
import { TitleCell } from 'components/content-pool/kendocells'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Articles } from 'components/icons/articles'
import { RasaContext } from 'context'
import { AggregationType, DateRangeFilter, FilterType, IndexName,
  SourceNameOptions, SuspectFilterDropdownOptions, toFilter } from 'elasticsearch/constants'
import { DateRangesAsDropdownOptions } from 'elasticsearch/constants'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import { ShareCell } from './share-cell'
import './styles.css'
import * as Utils from './utils'
import { ConnectedComponentClass } from 'react-redux'
import { ComponentType } from 'react'
import {Fields} from "../../shared/modals";

export class ArticlesComponent extends RasaAnalyticsComponent<any, any> {

  constructor(props) {
    super(props)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Articles'}
          description={['The articles your contacts are clicking on the most.']}
        />
        <div className="dropdown articles">
          <div className="grid-container">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                                selected={this.state.selectedDateRange.key}
                                onChange={this.dateChanged}/>
            {this.state.isSuspectClickAllowed && <DropdownComponent data={SuspectFilterDropdownOptions}
                                selected={this.state.selectedSuspectClick.key}
                                onChange={this.suspectedClickChanged}/>}
          </div>
          <div className="dropdown"><span>Source Name</span>
            <DropdownComponent data={SourceNameOptions}
                                selected={this.state.selectedSourceName.key}
                                onChange={this.sourceClickChanged}/></div>
          <div className="date-range-picker">
              {this.state.selectedDateRange.key === '7' ?
              <DateRangePicker onChange={this.createCustomDate}
                               min={this.state.minCustomDateRange}
                               max ={new Date()} /> : null}
            </div>
        </div>
      {this.state.isFilterLoaded &&
        <AnalyticsArticlesTable dateRange={this.state.selectedDateRange.value}
              suspectClick={this.state.selectedSuspectClick.value} sourceName={this.state.selectedSourceName.value}/>}
    </div>
    )
  }

}

interface ArticleStats {
  opens?: number,
  post_id: string,
  realClicks?: number,
  realUniqueRate?: number,
  realUniqueCount?: number,
  sourceName?: string,
  suspectClick?: number,
  suspectUniqueRate?: number,
  suspectUniqueCount?: number,
  totalClicks?: number,
  totalUniqueRate?: number,
  totalUniqueCount?: number,
  uniqueClicks: number,
}

interface PostInfo {
  custom_tags?: string,
  nlp_tags?: string,
  source_tags?: string,
  post_id?: string,
  title?: string,
  url?: string,
}

type Article = ArticleStats & PostInfo

type Articles = Article[]

interface ArticlesState {
  loaded: boolean,
  communityGuid: string,
  shareUrl: string,
  shortenUrl: string,
  user: any,
}

interface ArticlesProps extends ElasticsearchProps<Articles> {
  dateRange: any,
  suspectClick?: any,
  sourceName?: any,
}

const PRIMARY_AGG = 'post_id'
const PRIMARY_AGG_LEGACY = 'post_id_1'
const SECONDARY_AGG = 'source_name'

class ArticlesTableComponent extends ElasticsearchComponent<Articles, ArticlesProps, ArticlesState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private xlsxExport: any = null
  constructor(p: ArticlesProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      communityGuid: '',
      shareUrl: '',
      shortenUrl: '',
      user: {},
    }
    this.reportName = Constants.REPORT_NAMES.ARTICLES
  }

  public parseResponse(payload: any): Promise<Articles> {
    this.setState({
      loaded: true,
    })
    const aggregations = payload.aggregations[PRIMARY_AGG]
    const postIdsINeed = aggregations.buckets.map((r) => r.key)

    const totalUniqueCounts = aggregations.buckets.reduce((a, b) => {
      return a + b.unique.value;
    }, 0)

    return this.getOpens(postIdsINeed).then((result) => {

      const legacyAggregations = result.aggregations ? result.aggregations[PRIMARY_AGG_LEGACY] : {}
      const openAggregations = result.aggregations ? result.aggregations[PRIMARY_AGG] : {}

      const legacyBuckets = legacyAggregations.buckets ? legacyAggregations.buckets : []
      const openBuckets = (openAggregations.buckets ? openAggregations.buckets : []).concat(legacyBuckets)

      const statsResponse: ArticleStats[] = aggregations.buckets.map((aggregation: any) => {
        const sourceAggregate = aggregation.source_name.buckets[0]
        const opensRecord = openBuckets.filter((x) => x.key === aggregation.key)
        const opens = opensRecord.length ? opensRecord[0][Constants.DOC_COUNT] : 0

        const suspectClickAggregate = this.getAggregation(aggregation.suspect_click, 1)
        const suspectClicks = Utils.newCounterEntry(suspectClickAggregate, opens)
        const reaclClickAggregate = this.getAggregation(aggregation.suspect_click, 0)
        const realClicks = Utils.newCounterEntry(reaclClickAggregate, opens)
        return {
          opens,
          post_id: aggregation.key,

          realClicks: realClicks ? realClicks.total.count : 0,
          realUniqueRate: realClicks ? realClicks.unique.rate : 0,
          realUniqueCount: realClicks ? realClicks.unique.count : 0,

          sourceName: sourceAggregate ? sourceAggregate.key : '',

          suspectClick: suspectClicks ? suspectClicks.total.count : 0,
          suspectUniqueRate: suspectClicks ? suspectClicks.unique.rate : 0,
          suspectUniqueCount: suspectClicks ? suspectClicks.unique.count : 0,

          totalClicks: sourceAggregate ? sourceAggregate.doc_count : 0,
          totalUniqueCount: totalUniqueCounts ? totalUniqueCounts : 0,

          uniqueClicks: aggregation.unique ? aggregation.unique.value : 0,
        }
      })
      if (postIdsINeed.length > 0) {
        return this.getTopArticleDetails(postIdsINeed).then((postDetails: PostInfo[]) => {
          return statsResponse.map((a: ArticleStats) => {
            return ({
              ...a,
              ...this.postInfoFor(a, postDetails),
              uniqueClickRate: (a.uniqueClicks / a.opens) * 100,
            })
          })
        })
      } else {
        return statsResponse.map((a: ArticleStats) => {
          return ({
            ...a,
            uniqueClickRate: (a.uniqueClicks / a.opens) * 100,
          })
        })
      }
    })
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.config),
    ]).then(([activeCommunity, person, config]) => {
      this.communityId = activeCommunity.communityId
      this.setState({
        communityGuid: activeCommunity.data.community_guid,
        shortenUrl: config.shortenUrl,
        shareUrl: config.shareUrl,
        user: {
          email: person.email,
          firstName: person.firstName,
          lastName: person.lastName,
        },
      })
      this.search()
    })
  }
  public componentDidUpdate(oldProps: ArticlesProps) {
    if ( this.props.dateRange !== oldProps.dateRange || this.props.suspectClick !== oldProps.suspectClick ||
      this.props.sourceName !== oldProps.sourceName) {
      this.search()
    }
  }

  public searchPayload(): any {
    const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange)
    if (dateRangeFilter.gte && dateRangeFilter.lt || this.props.suspectClick ||
      this.props.sourceName) {
      const search = bodybuilder().size(0)
        .filter(FilterType.range, 'message_send_date', dateRangeFilter)
        .filter(FilterType.exists, 'post_subscription_id')

      if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
        search.addFilter(FilterType.term, 'suspect_click', 0)
      }
      return this.addAggregation(search, {
        type: AggregationType.terms,
        field: 'post_id',
        name: PRIMARY_AGG,
        extra: { size: '100' },
        childs: [{
          field: 'community_person_guid.keyword',
          type: AggregationType.cardinality,
          name: 'unique',
          extra: {
            precision_threshold: '40000',
          },
        }, {
          field: `${this.props.sourceName}.keyword`,
          name: SECONDARY_AGG,
          type: AggregationType.terms,
        }, {
            type: AggregationType.terms,
            field: 'suspect_click',
            name: 'suspect_click',
            unique_on: 'community_person_guid.keyword',
        }, {
          type: AggregationType.terms,
          field: 'issue_id',
          name: 'issue_id',
        }, {
          type: AggregationType.terms,
          field: 'post_type.keyword',
          name: 'post_type',
          unique_on: 'community_person_guid.keyword',
        }],
      }).build()
    }
  }

  public render = () => {
  const displayClickType: any = Utils.getDisplayedClickType(this.props.suspectClick)
  const sourceTitle: any = this.props.sourceName === SourceNameOptions[0].value ?
  'Source' :
  'Publisher'
  return <div>
    <div className="articles-chart">
      {this.props.results && this.props.results.length > 0 ?
      <div className="articles">
        <Button
          disabled={this.props.results.length < 1}
          onClick={() => this.xlsxExport.save()}>
          Export xlsx
        </Button>
        <ExcelExport data={this.props.results}
          fileName="RasaAdminReports.xlsx"
          ref={(exporter) => {this.xlsxExport = exporter}}>
        <Grid data={this.props.results.map((a: any) => ({
            ...a,
            linkShortenUrl: this.state.shortenUrl,
            shareUrl: Utils.shareUrl(this.state.communityGuid, a, this.state.shareUrl),
            user: this.state.user,
          }))} className="analytics-counts-grid">
          <GridColumn field="sourceName" title={sourceTitle} width={250} />
          <GridColumn field="title" title="Title" cell={this.ClickableTitleCell} width={450}/>
          <GridColumn field="opens" title="Opens" width={120}/>

          <GridColumn field={displayClickType.field} title={displayClickType.title} width={120} />
          <GridColumn field="uniqueClicks" title="Unique Clicks" width={120}/>
          <GridColumn field="uniqueClickRate" title="Unique Click Rate" width={0}/>

          <GridColumn field="suspectClick" title="Total Suspect Clicks" width={0}/>
          <GridColumn field="suspectUniqueCount" title="Unique Suspect Clicks" width={0}/>
          <GridColumn field="suspectUniqueRate" title="Unique Suspect Rate" width={0}/>

          <GridColumn field="realClicks" title="Total Non-Suspect Clicks" width={0}/>
          <GridColumn field="realUniqueCount" title="Unique Non-Suspect Clicks" width={0}/>
          <GridColumn field="realUniqueRate" title="Unique Non-Suspect Rate" width={0}/>

          <GridColumn cell={ShareCell} field="url" title="Share" width={150}/>
        </Grid>
        </ExcelExport>
      </div> :
      <div>
        <p className="no-data-tag">
        {Constants.NO_DATA_COPY}
        </p>
      </div>}
    </div>
  </div>
  }

  private postInfoFor = (a: ArticleStats, postDetails: PostInfo[]): PostInfo => {
    const hit = postDetails.find((p) => p.post_id === a.post_id)
    return hit ? hit : {}
  }
  private ClickableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => {this.openLink(e)}} />
  }
  private openLink = (p: any) => {
    window.open(p.url, '_blank')
  }
  private getOpens = (postIds: string[]): Promise<any> => {
    if (postIds.length) {
      const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange)
      if (dateRangeFilter.gte && (
            dateRangeFilter.lt || this.props.suspectClick || this.props.sourceName
      ) ) {

        // Pre summer 2024, we only put opened message run details into ES.  They had a "post_id" field.
        // Summer 2024, we moved to a different structure where all message run details went to es.
        // These have record_type/record_id and a status field.  So: to find all opens, we need to look
        // for records that match either pattern.
        const recordTypeQuery = (build) => {
          return build.filter(FilterType.term, 'record_type', 'article')
                      .filter(FilterType.terms, 'record_id', postIds)
                      .filter(FilterType.term, 'status', 'open')
        }
        const matchIdsQuery = (build) => {
          return build.orQuery(FilterType.terms, 'post_id', postIds)
              .orQuery('bool', recordTypeQuery)
              .queryMinimumShouldMatch(1)
        }
        const search = bodybuilder().size(0)
          .filter(FilterType.range, 'message_send_date', dateRangeFilter)
          .query('bool', matchIdsQuery)

        const payload = this.addAggregation(search, {
          type: AggregationType.terms,
          field: 'record_id',
          name: PRIMARY_AGG,
          extra: { size: postIds.length.toString() },
          unique_on: 'community_person_id',
        })
        const payload2 = this.addAggregation(payload, {
          type: AggregationType.terms,
          field: 'post_id',
          name: PRIMARY_AGG_LEGACY,
          extra: { size: postIds.length.toString() },
          unique_on: 'community_person_id',
        })
        const query = payload2.build()
        return this.queryIndex(query, IndexName.MESSAGE_RUN_DETAIL)
      }
    }
    return Promise.resolve({})
  }

  private getTopArticleDetails = (postIds: string[]): Promise<PostInfo[]> => {
    const url: string = AjaxWrapper.getServerUrl() + `/dataset/${this.communityId}/topArticleDetails`
    const payload = {
      postIds,
    }
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
    .then((response: any[]) => {
      return response[0].map((x: any) => {
        return {
          custom_tags: x.custom_tags,
          nlp_tags: x.nlp_tags,
          post_id: x.id,
          source_tags: x.source_tags,
          title: x.title,
          url: x.url,
        }
      })
    })
    .catch(() => ([]))
  }
}

export const AnalyticsArticlesTable: ConnectedComponentClass<ComponentType<ArticlesTableComponent>, Fields> = GenericRedux.registerNewComponent(
  ArticlesTableComponent, 'home_articles', {})
